import { useCallback, useMemo } from "react";
import styled from "styled-components";
import {
  ArrowRightIcon,
  ArrowsPointingOutIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Internal } from "@sablier/v2-components/atoms";
import { Button, Chain, Icon, Tooltip } from "@sablier/v2-components/molecules";
import { routes } from "@sablier/v2-constants";
import { useChainData } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { IChain } from "@sablier/v2-types/chain";
import { vendors } from "@sablier/v2-utils";
import { useRouter } from "next/router";
import {
  useAccount,
  useCartStoreList,
  useChain,
  useCoverStreamCart,
} from "~/client/hooks";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.row}
  ${(props) => props.theme.animations.slideUp};
  & {
    position: fixed;
    bottom: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    z-index: ${(props) => props.theme.sizes.zIndexCart};
    justify-content: center;
    width: 100vw;
    margin: 0 auto;
    padding: 0;
    pointer-events: none;
  }
`;

const Effect = styled.div`
  box-shadow: 0px 10px 48px -12px ${(props) => props.theme.colors.dark100};
`;

const Box = styled.div`
  ${(props) => props.theme.styles.row}

  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 2 / 3);
    width: auto;
    padding: calc(${(props) => props.theme.sizes.edge} * 1);
    padding-inline: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.dark050};
    pointer-events: all;

    p,
    span,
    label {
      font-weight: 600;
      font-size: 11pt;
      line-height: 140%;
    }
  }
`;

const Checkbox = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    --size: 12px;
    --thickness: 4;
    flex-shrink: 0;
    justify-content: center;
    width: 18px;
    height: 18px;

    color: ${(props) => props.theme.colors.white};
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors.secondaryDesaturated};
  }
`;

const Element = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    --size: 18px;
    gap: calc(${(props) => props.theme.sizes.edge} * 2 / 3);
    color: ${(props) => props.theme.colors.white};
  }
`;

const Explainer = styled.p`
  ${(props) => props.theme.animations.slideUp}
  & {
    --animation-slide-up-distance: 10px;
    animation-duration: 300ms;
  }
`;

const Actions = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 2 / 3);
  }
`;

const Others = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 2 / 3);
  }
`;

const Divider = styled.div`
  width: 2px;
  height: 20px;
  border-radius: 1px;
  background-color: ${(props) => props.theme.colors.border};
`;

const Network = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    --size: 22px;
    position: relative;
    justify-content: center;
    width: ${(props) => props.theme.sizes.buttonMini};
    height: ${(props) => props.theme.sizes.buttonMini};
    border: 2px solid ${(props) => props.theme.colors.border};
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.border};
    cursor: pointer;
    img {
      filter: grayscale(100%);
      transition: filter 150ms;
    }
  }

  &:hover,
  &:active {
    img {
      filter: grayscale(0%);
      transition: filter 150ms;
    }
  }
`;

const Count = styled.div`
  ${(props) => props.theme.styles.row}
  ${(props) => props.theme.animations.slideUp}
      & {
    --animation-slide-up-distance: 10px;
    position: absolute;
    bottom: -6px;
    right: -6px;
    z-index: ${(props) => props.theme.sizes.zIndexOver};
    justify-content: center;
    width: 16px;
    height: 16px;
    color: ${(props) => props.theme.colors.white};

    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.secondaryDesaturated};

    animation-duration: 300ms;

    & > p {
      font-weight: 700;
      font-size: 9pt;
    }
  }
`;

const Clear = styled(Button)``;
const Manage = styled(Button)``;

const Wrapper = styled(WrapperPartial)`
  &[data-purpose="relocate"],
  &[data-purpose="relocate-disclaimer"] {
    ${Box} {
      gap: 8px;
      padding: 8px 12px;
    }
  }

  &[data-purpose="relocate-disclaimer"] {
    bottom: calc(${(props) => props.theme.sizes.edge} * 4);
  }

  ${(props) => props.theme.medias.maxLG} {
    &[data-purpose="relocate-disclaimer"] {
      bottom: calc(${(props) => props.theme.sizes.edge} * 1);
    }
  }

  ${(props) => props.theme.medias.maxMD} {
    ${Divider} {
      display: none;
    }

    ${Others} {
      ${Element}, ${Divider} {
        display: none;
      }
    }

    ${Box} {
      padding: calc(${(props) => props.theme.sizes.edge} * 1);
    }

    ${Manage} {
      padding: 0;
      p {
        position: relative;
        svg {
          width: 18px;
          height: 18px;
          margin-top: 6px;
          stroke-width: 1.9;
        }
      }
    }

    &[data-purpose="relocate"],
    &[data-purpose="relocate-disclaimer"] {
      ${Box} {
        gap: 8px;
        padding: 8px 12px;
      }
    }
  }

  ${(props) => props.theme.medias.maxSM} {
    bottom: 0;

    ${Effect} {
      width: 100%;
    }
    ${Element} {
      gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      p {
        font-size: 10.5pt;
        line-height: 1.4;
      }
      div:last-child {
        --size: 20px;
        p {
          display: none;
        }
      }
    }
    ${Box} {
      gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 70px;
      padding-inline: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      border-radius: 0;
    }

    &[data-purpose="relocate"],
    &[data-purpose="relocate-disclaimer"] {
      display: none;
    }
  }
`;

interface Props {
  className?: string;
}

function Cart({ className }: Props) {
  const { connector } = useAccount();
  const { switchTo, ...current } = useChain();
  const { find } = useChainData();
  const { list, reset } = useCartStoreList();

  const { setOpen } = useCoverStreamCart();

  const doManage = useCallback(() => {
    setOpen(true, { tab: "manage" });
  }, [setOpen]);

  const { t } = useT();

  const counts = useMemo(() => {
    return list.reduce((group, stream) => {
      const chainId = stream.chainId;

      if (group[stream.chainId]) {
        group[stream.chainId].count += 1;
      } else {
        group[stream.chainId] = {
          count: 1,
          definition: find(chainId),
        };
      }

      return group;
    }, {} as Record<number, { count: number; definition: IChain | undefined }>);
  }, [find, list]);

  const configuration = useMemo(() => {
    if (!_.isNil(current) && current.id) {
      const selected = counts[current.id];
      if (selected?.count) {
        return {
          content: t("descriptions.stream.cart.streamsSelectedOn", {
            count: selected.count,
          }),
          active: current,
          others: Object.values(counts).filter(
            ({ definition }) =>
              definition && definition?.chainId !== current.id,
          ),
        };
      }
    }

    return {
      content: t("descriptions.stream.cart.streamsSelectedOnGeneric"),
      active: undefined,
      others: Object.values(counts),
    };
  }, [current, counts, t]);

  const onSwitch = useCallback(
    (chainId: number) => {
      if (switchTo) {
        (async () => {
          try {
            await switchTo({ chainId, connector });
          } catch (error) {
            vendors.crash.log(error);
          }
        })();
      }
    },
    [connector, switchTo],
  );

  return (
    <Wrapper className={className}>
      <Effect>
        <Box>
          <Checkbox>
            <Icon value={CheckIcon} />
          </Checkbox>
          <Element>
            <Explainer key={configuration.content}>
              {configuration.content}
            </Explainer>
            {configuration.active ? <Chain {...configuration.active} /> : false}
          </Element>
          {configuration.active ? (
            <Actions>
              <Manage
                accent={"iconic"}
                appearance={"outline"}
                isMini
                title={_.capitalize(t("words.manage"))}
                titleShort={<ArrowsPointingOutIcon />}
                onClick={doManage}
              />
            </Actions>
          ) : (
            false
          )}
          {configuration.others.length ? (
            <Others>
              {configuration.active ? (
                <>
                  <Divider />
                  <Element>
                    <p>{t("structs.otherChains")}:</p>
                  </Element>
                </>
              ) : (
                false
              )}
              {configuration.others.map(({ count, definition }) => (
                <Tooltip
                  color={"dark250"}
                  container={{ padding: "6px 8px" }}
                  key={definition?.chainId}
                  value={t("structs.switchTo", { name: definition?.name })}
                >
                  <Network
                    onClick={() => {
                      onSwitch(definition!.chainId);
                    }}
                  >
                    <Chain {...definition} isIconShown isNameShown={false} />
                    <Count key={count}>
                      <p>{count}</p>
                    </Count>
                  </Network>
                </Tooltip>
              ))}
              {!configuration.active ? (
                <Actions>
                  <Manage
                    accent={"iconic"}
                    appearance={"outline"}
                    isMini
                    title={_.capitalize(t("words.manage"))}
                    titleShort={<ArrowsPointingOutIcon />}
                    onClick={doManage}
                  />
                </Actions>
              ) : (
                false
              )}
            </Others>
          ) : (
            false
          )}
          <Divider />
          <Clear
            accent={"iconic"}
            appearance={"outline"}
            isMini
            onClick={() => {
              reset();
            }}
            title={""}
            right={XMarkIcon}
            tooltip={{ value: t("structs.clearList") }}
          />
        </Box>
      </Effect>
    </Wrapper>
  );
}

function Relocate({
  className,
  purpose = "relocate",
}: Props & {
  purpose?: "relocate" | "relocate-disclaimer";
}) {
  const { t } = useT();
  return (
    <Wrapper className={className} data-purpose={purpose}>
      <Internal to={routes.client.pages.dashboard.builder()}>
        <Effect>
          <Box>
            <Checkbox>
              <Icon value={CheckIcon} />
            </Checkbox>
            <Element>
              <p>{t("descriptions.cart.relocate")}</p>
            </Element>
            <Actions>
              <Button
                accent={"white"}
                appearance={"transparent"}
                isMini
                isUnpadded
                isPreview
                title={""}
                right={ArrowRightIcon}
              />
            </Actions>
          </Box>
        </Effect>
      </Internal>
    </Wrapper>
  );
}

function Manager({ className }: Props) {
  const router = useRouter();
  const { list } = useCartStoreList();

  if (!list.length) {
    return false;
  }

  switch (router.pathname) {
    case routes.client.pages.dashboard.route:
    case routes.client.pages.profile.route:
      return <Cart className={className} />;
    case routes.client.pages.airstream.dashboard.route:
      return <Relocate className={className} purpose={"relocate-disclaimer"} />;
    case routes.client.pages.airstream.profile.route:
      return <Relocate className={className} />;

    default:
      return false;
  }
}

export default Manager;
